import React from "react";
import Icon from "../Icon/Icon";
import { IconWithoutChildrenProps } from "../types";

const IconWarning: React.FC<IconWithoutChildrenProps> = (
  props: IconWithoutChildrenProps
): React.ReactElement => {
  return (
    <Icon {...props} title={props.title || "warning"}>
      <svg viewBox="0 0 32 32">
        <path d="M16.874 6.514l10 18A1 1 0 0126 26H6a1 1 0 01-.874-1.486l10-18a1 1 0 011.748 0zM16 9.06L7.7 24h16.6L16 9.06zM16 20a1 1 0 110 2 1 1 0 010-2zm0-7a1 1 0 011 1v4a1 1 0 01-2 0v-4a1 1 0 011-1z" />
      </svg>
    </Icon>
  );
};

export default IconWarning;
